/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
//@import '~@videogular/ngx-videogular/fonts/videogular.css';

.ltr {
  direction: ltr;
}

.borderit {
  border-right: 1px solid #ececec;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.round-button {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.color-picker {
  left: auto !important;
  top: auto !important;
}

.ion-text-center, [text-center] {
  text-align: center !important;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.background-subtab {
  background: #f7f7f7;
}

.background-primary {
  background: var(--ion-color-primary);
  color: white;
}

.background-secondary {
  background: var(--ion-color-secondary);
}

.background-primary {
  background-color: var(--ion-color-primary);
}

.background-success {
  background: var(--ion-color-success);
}

.background-medium {
  background: var(--ion-color-medium);
}

.background-light {
  background-color: var(--ion-color-light);
}

.background-light2 {
  background-color: #f5f5f5;
}

.background-disable {
  background-color: var(--ion-color-disable);
}

.background-warning {
  background: var(--ion-color-warning);
}

.background-danger {
  background: var(--ion-color-danger);
}

.background-dark {
  background: var(--ion-color-dark);
}

.color-a {
  color: var(--background-color-a);
  --color: var(--background-color-a);
}

.background {
  background: white;
  //background: transparent !important;
}

.ion-page {
  overflow-x: hidden !important;
}

//body {
//  direction: var(--app-direction);
//}
//body ion-content {
//  direction: var(--app-direction);
//}

ion-content {
  // overwrite inline styles

  //--offset-bottom: auto !important;
  //--overflow: hidden;
  overflow: auto;


}

ion-card.no-style {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
}

//.alert-wrapper {
//  direction: var(--app-direction);
//}

.text-end {
  text-align: end;
}

.text-start {
  text-align: start;
}

.in-item {
  max-width: 100% !important;
}

ion-icon.danger, .danger-box {
  color: var(--ion-color-danger);
}

.h1 {
  font-size: 1.9rem !important;
}

.h2 {
  font-size: 1.6rem !important;
}

.h3 {
  font-size: 1.4rem !important;
}

.h4 {
  font-size: 1.2rem !important;
}

.h5 {
  font-size: 1rem !important;
  line-height: 1rem;
}

.h1 .subTitle {
  font-size: 1.6rem !important;
}

.h2 .subTitle {
  font-size: 1.3rem !important;
}

.h3 .subTitle {
  font-size: 1rem !important;
}

.h4 .subTitle {
  font-size: 0.7rem !important;
}

.h5 .subTitle {
  font-size: 2.5rem !important;
  line-height: 1rem;
}

.h1 .title {
  font-size: 1.9rem !important;
}

.h2 .title {
  font-size: 1.6rem !important;
}

.h3 .title {
  font-size: 1.3rem !important;
}

.h4 .title {
  font-size: 1rem !important;
}

.h5 .title {
  font-size: 0.5rem !important;
  line-height: 1rem;
}

@media screen and (min-width: 990px) {

  .h1 {
    font-size: 2.3rem !important;
  }

  .h2 {
    font-size: 2.1rem !important;
  }

  .h3 {
    font-size: 1.2rem !important;
  }

  .h4 {
    font-size: 1rem !important;
  }

  .h4 .subTitle {
    font-size: 0.8rem !important;
  }

  .h5 {
    font-size: 0.8rem !important;
  }

  .big-modal {
    --width: 60%;
    --height: 90%;
  }

}

@media screen and (max-width: 767px) {


  .h1 {
    font-size: 1.4rem !important;
  }

  .h2 {
    font-size: 1.1rem !important;
  }

  .h3 {
    font-size: 0.9rem !important;
  }

  .h4 {
    font-size: 0.8rem !important;
  }

  .h5 {
    font-size: 0.7rem !important;
  }

  .hide-sm {
    display: none;
  }

  .lgPadding img {
    height: 80px;
  }

  .lgPadding h1 {
    font-size: 1rem;
  }

  //.sectionCard {
  //  height: 150px;
  //}


  .h3 {
    font-size: 1rem !important;
  }
  .h4 {
    font-size: 0.9rem !important;
  }
  .h5 {
    font-size: 0.5rem !important;
    line-height: 0.5rem;
  }
}

@media screen and (min-width: 767px) {


  .sectionCard {
  }

  .hide-xl {
    display: none;
  }

  .lgPadding {
    padding: 5px;
  }

  .lgPadding h1 {
    font-size: 1.5rem;
  }

  .lgPadding img {
    //height: 100px;
  }

  ion-col.main-col {
    //padding: 0 25px;
  }

  .backgroundWhite {
    //background-color: rgba(255, 255, 255, 0.1);
    background: #fff;
  }

}

.h4 .boldField
{
  font-weight: bolder;
  font-size: 1rem !important;
}
.h3 .boldField
{
  font-weight: bolder;
  font-size: 1.4rem !important;
}

@media screen and (max-width: 990px) {
  //.hideSM {
  //  display: none;
  //}
}

.bold {
  font-weight: bolder;
}

.italic {
  font-style: oblique;
}

.nh1 span, .nh1 ion-icon, .nh1 ion-card-title, .nh1 div, .nh1 ion-label,
.nh1 h1, .nh1 h2, .nh1 h3, .nh1 h4, .nh1 p, .nh1 ion-button, .nh1 div, .nh1 ion-col, .nh1 ion-card-subtitle {
  font-size: 3rem !important;
}

.nh2 span, .nh2 ion-icon, .nh2 ion-card-title, .nh2 div, .nh2 ion-label,
.nh2 h1, .nh2 h2, .nh2 h3, .nh2 h4, .nh2 p, .nh2 ion-button, .nh2 div, .nh2 ion-col, .nh2 ion-card-subtitle {
  font-size: 2.3rem !important;
}

.nh3 span, .nh3 ion-icon, .nh3 ion-card-title, .nh3 div, .nh3 ion-label,
.nh3 h1, .nh3 h2, .nh3 h3, .nh3 h4, .nh3 p, .nh3 ion-button, .nh3 div, .nh3 ion-col, .nh3 ion-card-subtitle {
  font-size: 1.5rem !important;
}

.nh4 span, .nh4 ion-icon, .nh4 ion-card-title, .nh4 div, .nh4 ion-label,
.nh4 h1, .nh4 h2, .nh4 h3, .nh4 h4, .nh4 p, .nh4 ion-button, .nh4 div, .nh4 ion-col, .nh4 ion-card-subtitle {
  font-size: 1.5rem !important;
}

.nigodiot .tabs-body,
.nigodiot ion-item,
.nigodiot div, .nigodiot ion-content, .nigodiot ion-row,
.nigodiot span, .nigodiot ion-icon, .nigodiot ion-card-title, .nigodiot ion-row,
.nigodiot ion-label,
.nigodiot ion-item,
.nigodiot .item-inner,
.nigodiot ion-toolbar,
.nigodiot h1, .nigodiot h2, .nigodiot h3, .nigodiot h4,
.nigodiot p, .nigodiot ion-button, .nigodiot div, .nigodiot ion-col, .nigodiot ion-card-subtitle {
  color: white !important;
  background-color: black !important;
  --background: black !important;
}

.nigodiotd .tabs-body,
.nigodiotd ion-item,
.nigodiotd div, .nigodiotd ion-content, .nigodiotd ion-row,
.nigodiotd span, .nigodiotd ion-icon, .nigodiotd ion-card-title, .nigodiotd ion-row,
.nigodiotd ion-label,
.nigodiotd ion-item,
.nigodiotd .item-inner,
.nigodiotd ion-toolbar,
.nigodiotd h1, .nigodiotd h2, .nigodiotd h3, .nigodiotd h4,
.nigodiotd p, .nigodiotd ion-button, .nigodiotd div, .nigodiotd ion-col, .nigodiotd ion-card-subtitle {
  color: #0b3e6f !important;
  background-color: #4c8dff !important;
  --background: #4c8dff !important;
}

//.nh1 {
//  font-size: 3rem;
//  background-color: white;
//}

//.nh2 span, .nh2 ion-card-title, .nh2 div, .nh2 ion-label {
//  font-size: 1rem !important;
//}
//
//.nh2 {
//  font-size: 1rem !important;
//  background-color: white;
//}

.height-fit {
  height: fit-content;
}

.catSlider > div {
  //background-color: --button-a-background;
}


.catSlider {
  width: 100%; //25%;
  overflow-y: auto;
  //height: 750px;
  //position: fixed;
}

.catSlider1 {
  //width: 15%;
}

.split-pane-md.split-pane-visible > .split-pane-side {
  max-width: 10%;
  --width: 80px;
  width: 80px;
  --min-width: 40px;
}

.underline {
  text-decoration: underline;
}

.smallmenu .app-menu {
  max-width: 120px !important;
  min-width: 120px !important;
}

.smallmenu .app-menu {
  max-width: 200px !important;
  min-width: 200px !important;
}

.cart-menu {
  max-width: 300px !important;
  min-width: 300px !important;
}
.bigCartMenu {
  max-width: 700px !important;
  min-width: 700px !important;
}

//.bigMenu {
//  --width: 180px;
//  width: 180px;
//  //min-width: 20%;
//    min-width: 150px!important;
//    max-width: 150px!important;
//  }

.listTable .modelScroll {
  display: block;
  width: 100%;
}

.item-no-border {
  --border-style: none;
  border-style: none;
  box-shadow: none;
}

.bigShow {
  height: 300px;
  width: 300px;
  left: 400px;
  position: fixed;
  z-index: 99999999999;
}

.listTableContainer {
  width: 10000px;
  //width: max-content;
}


.listTable {
  width: 100%;
}


.listTable th {

}

.listTable td {
  //max-width: 120px;
  border: 1px solid #ddd;
  padding: 0px 1.5px;
  //line-height: 1.4rem;
  height: 40px;
}

.listTable tr {

}

.listTable tr:hover {
  cursor: pointer;
  background-color: #c8c8c8 !important;
}


.wideOverflow {
  max-height: 100%;
  overflow: auto;
}

.overflow {
  overflow: auto;
}

.imageCenter {
  background-position: center;
  background-size: contain;
}

.appborderbig {
  border: 4px solid #cfcfcf;
  border-radius: 5px;
  padding: 0px;
}

.appborderSmall {
  border: 2px solid var(--ion-color-medium);
}

.appborder {
  border: 2px solid var(--ion-color-medium);
  //box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  //border-radius: 8px;
  --border-radius: 0px !important;
  border-radius: 0px !important;
  //padding: 2px;
}

@media (max-width: 1000px) {
  .appborder {
    border: 2px solid var(--ion-color-medium);
  }
}

.appborderRadius {
  //border: 1px solid #c9c9c9;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  border-radius: 7px;
  --border-radius: 7px;
  //padding: 2px;
}

.appborder1 {
  border: 1px solid #e0dfdf;
  border-radius: 2px;
  padding: 2px;
}

.appborder2 {
  border: 15px solid #e0dfdf;
  border-radius: 20px;
  padding: 10px;
}

.darkBorder {
  border: 1px solid #858282;
  box-shadow: rgba(98, 8, 8, 0.2) 0px 3px 1px -2px, rgba(232, 10, 10, 0.14) 0px 2px 2px 0px, rgba(246, 104, 104, 0.12) 0px 1px 5px 0px;
  border-radius: 2px;
}

.dangerBorder {
  border: 1px solid #ff0000;
  box-shadow: rgba(98, 8, 8, 0.2) 0px 3px 1px -2px, rgba(232, 10, 10, 0.14) 0px 2px 2px 0px, rgba(246, 104, 104, 0.12) 0px 1px 5px 0px;
  border-radius: 7px;
  padding: 2px;
}

.appbackground {
  --background: #fafafa;
  background: #fafafa;
  //background: linear-gradient(6deg, rgba(255, 255, 255, 1) 0%, rgba(222, 222, 222, 1) 52%, rgb(255 255 255) 98%, rgb(48 83 160) 100%);
  //linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  //--background-size: 400% 400%;
  //background-size: 400% 400%;
  //animation: gradient 135s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.appborderneat {
  border: 1px solid #cfcfcf;
  padding: 2px;
}

.appborderTop {
  border-top: 1px solid #cfcfcf;
  border-radius: 10px;
  padding: 2px;
}

.appborderBottom {
  border-bottom: 1px solid #cfcfcf;
}

.appborderRadius2 {
  border-radius: 6px;
}

.appborderSides {
  border-left: 1px solid #f6f6f6;
  border-right: 1px solid #f6f6f6;
}

.appborderRightBottom {
  border-right: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
  padding-top: 2px;
}

.appborderRight {
  border-right: 1px solid #f6f6f6;
}

.edittd {
  display: none;
}

.floatField {
  float: left;
  //margin: 0px 5px;
  //width: 230px;
}

.field:hover {
  min-height: 30px;
  border: 1px dashed;
  cursor: pointer;
  //padding: 2px;
  //white-space: nowrap;
  //font-size: 1rem;
}

//.edittd {
////  display: inline-block;
////}

.listTable > tbody > tr:nth-of-type(odd) {
  background-color: #efefef;
}

.flexTable {
  //flex-basis: 326px;
  //transform: translateX(130px);
  background-color: rgba(var(--pulse-floating-background-color), 0.95);
  box-shadow: 0px 0px 8px -6px;
  z-index: 2;
}

.sc-ion-alert-md-h {
  #direction: rtl;
}

.buttonHover {
  cursor: pointer;
}

.mycards {
  background-color: #a4a5a4;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
}

.mycards ion-card-header {
  //background-image: url("assets/dashboard/background-patterns.jpg");
  //background-color: teal;
  //font-size: 0.9rem;
  //color: white;
}


.buttonHover:hover {
  background-color: inherit;
  --background-color: inherit;
  background: inherit;
  //background-color: #c4c5c4;
  cursor: pointer;
  filter: brightness(95%);
}

.fontOnHoverBig:hover {
  background-color: #c4c5c4;
}

ion-grid {
  //padding: 0px;
}

ion-col {
  //padding: 0px;
}

ion-card {
  //padding: 0px;
}


.card-content-md h2 {
  font-size: 2rem;
}

.card-content-md h3, .card-content-md h4, .card-content-md h5, .card-content-md h6 {
  font-size: 1rem;
}

td {
  //font-size: 2rem;
}


.card-content-md p {
  font-size: 1rem;
}

.card-content-md td p {
  font-size: 1rem;
}

.card-content-md td p h2 {
  font-size: 2rem !important;
}

.card-content-md td p h3 {
  font-size: 1rem !important;
}

//.nowrap, .nowrap span {
//  white-space: normal;
//}
.nowrap, .nowrap span {
  white-space: nowrap;
}

.topBanner .img1 {
  width: 100%;
  max-height: 800px;
}

.topItemImage .img1 {
  max-height: 600px !important;
  height: auto !important;
}

.popover-content {
  width: 600px !important;
  top: 0px !important;
  box-shadow: #383838 -18px -14px 75px;
  max-height: 500px;
}

.popover-content {
  top: 100px !important;
}

.ion-page {
  overflow: auto;
}

.bigger-popover {
  --width: 1400px !important;
  //--min-height: 800px;
  --min-width: 1400px !important;
  --max-width: 1400px !important;
}

.app-admin-box {
  max-width: 80% !important;
  min-width: 1000px !important;
  width: 1000px;
  --width: 1000px;
}

@media (min-width: 1200px) {
  .bigger-popover {
    --width: 1400px !important;
    --min-width: 1400px !important;
    --max-width: 1400px !important;
    box-shadow: #383838 -18px -14px 75px;
  }
}

@media (min-width: 1700px) {
  .app-admin-box {
    max-width: 80% !important;
    min-width: 1300px !important;
    width: 1300px;
    --width: 1300px;
  }
}

@media (max-height: 700px) {

  .bigger-popover .popover-content {
    //max-height: 600px;
  }
}

.popover-viewport.sc-ion-popover-md {
  overflow: scroll;
}

@media (max-height: 1000px) {

  .bigger-popover .popover-content {
    max-height: 900px;
  }
}

@media (max-height: 1200px) {

  .bigger-popover {
    top: -300px !important;
  }
  .bigger-popover .popover-content {
    max-height: 1100px;

  }
}

.popover-content {
  overflow: scroll;
}

.right-popup .popover-content {
  top: 40px !important;
  max-width: 100%;
  left: unset !important;
  right: 0;
  width: 1000px !important;
  box-shadow: #383838 -18px -14px 75px;
}

.sc-ion-popover-md-h {
  overflow-y: scroll;
}

ion-popover {
  --width: 80%;

  //.note-popover, .center-popover {
  //top: 50% !important;
  //left: 50% !important;
  //transform: translate(-50%, -50%) !important;
  //width: 80%;

  .bar {
    padding: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--main-top);
    color: var(--main-top-text);
  }

  .box {
    padding: 10px 0;
  }


  ion-content {
    background: #fff;
    --background: #fff;

  }

  //}

  //.note-popover .popover-content, .center-popover.sm-popup .popover-content {
  //  max-width: 350px;
  //}
}

.main-back {
  background: repeating-linear-gradient(27deg, #f5f9ff, #ffffff 94px, #e7e9f3 10px, #d2d6e9 20px);
  --background: repeating-linear-gradient(27deg, #f5f9ff, #ffffff 94px, #e7e9f3 10px, #d2d6e9 20px);
}

.sm-popup {
  --width: 350px;
  //top: 0px !important;
  box-shadow: #383838 -18px -14px 75px;
}

.smaller-popover {
  --width: 350px;
  top: 0px !important;
  box-shadow: #383838 -18px -14px 75px;
}


.noBackground .input-wrapper,
.noBackground .item-native,
.noBackground .item-inner {
  background: transparent !important;
  border: 0px !important;
}

.noBackground {
  background: transparent !important;
}

.fullWidth {
  width: 100% !important;
}

.fullHeight {
  height: 100% !important;
  max-height: 100% !important;
}

.minfullHeight {
  min-height: 100% !important;
}

.whiteSpaceUnset {
  white-space: unset;
}

.whiteSpaceNormal {
  white-space: normal;
}

.whiteSpacePreline {
  white-space: pre-line;
}

.minFullWidth {
  min-width: 100%;
}


@media (min-width: 0) and (max-width: 767px) {
  .hide-only-sm {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .hide-only-md {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .hide-only-lg {
    display: none;
  }
}

@media (min-width: 1200px) {
  .hide-only-xl {
    display: none;
  }

  .right-popup .popover-content {
    width: 1600px !important;
  }

}

.smallpadding {
  padding: 4px;
}

.smallmargin {
  margin: 4px;
}

.smallpaddingBottom {
  padding-bottom: 3px;
}

.smallmarginBottom {
  margin-bottom: 4px;
}

.smallmarginBottomHor {
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 2px;
  margin-left: 2px;
}

.verrysmallpadding {
  padding: 1px;
}

.verrysmallmargin {
  padding: 1px;
}

.pointer {
  cursor: pointer;
}

.box-onactive:active {
  -webkit-filter: brightness(90%);
}

.fontinherit {
  font-size: inherit;
}

.alert-radio-group.sc-ion-alert-md, .alert-checkbox-group.sc-ion-alert-md {
  //direction: rtl;
}

.showOnHoverRegular .showOnHoverSon {
  display: none;
}

.showOnHoverRegular:hover .showOnHoverSon {
  display: inline-block;
}

.showOnHoverRegular2 .showOnHoverSon2 {
  display: none;
}

.showOnHoverRegular2:hover .showOnHoverSon2 {
  display: inline-block;
}

.showOnHoverRegular .hideOnHoverSon {
  display: initial;
}

.showOnHoverRegular:hover .hideOnHoverSon {
  display: none;
}

.showOnHoverRegular:hover .showOnHoverSonMark {
  background: #f1f1f1;
  cursor: pointer;
}

.absolute {
  position: absolute;
}


.sticky {
  position: sticky;
  top: 0px;
  z-index: 90999999;
}

.stickyBottom {
  position: sticky;
  bottom: 0px;
  z-index: 90999999;
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

body {
  //scrollbar design
  .template-box::-webkit-scrollbar {
    width: 5px;
  }

  .template-box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  .template-box::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

ion-item.clickable:active, ion-item.clickable:hover {
  filter: brightness(90%);
  cursor: pointer;
}

.ion-item-no-padding {
  --padding-end: 0px;
  --padding-start: 0;
  --inner-padding-end: 0px;
}

ion-content {
  //::-webkit-scrollbar {
  //  width: 10px;
  //}

  /* Track */
  //::-webkit-scrollbar-track {
  //  box-shadow: inset 0 0 5px grey;
  //  border-radius: 10px;
  //}
  //
  ///* Handle */
  //::-webkit-scrollbar-thumb {
  //  background: red;
  //  border-radius: 10px;
}

ion-content.hide-scroll {
  // overwrite inline styles
  --offset-bottom: auto !important;
  --overflow: hidden;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.flex-box {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row-child {
  flex: auto;
}

.flex-nowrap {
  display: flex;
  align-items: center;
}

.gap-10 {
  gap: 10px;
}

.gap-3 {
  gap: 3px;
}

.px-1 {
  padding: 0 2px !important;
}

.space-between {
  justify-content: space-between;
}


.justify-center {
  justify-content: center;
}

.main-grid1 {
  display: flex;
  height: 100%;

  .main-col-main {
    width: 60%;
    //overflow: scroll;
    //max-height: 850px;
  }

  .main-col {
    width: 60%;
    //overflow: scroll;
    //max-height: 850px;
  }

  .main-col-2 {
    width: 80%;
    //overflow: scroll;
    //max-height: 850px;
  }

  .side1-col {
    width: 20%;
    align-self: flex-start;
  }

  .cart-col {
    width: 20%;
    align-self: flex-start;
    height: 95%;
    //height: 750px;

    //background: #fff;
    //border-radius: 10px;
    //margin: 4px 5px;
    //border: 1px solid #e3e3e2;
    //overflow: hidden;
  }


  @media (max-width: 1400px) {
    .side1-col {
      display: none;
    }

    .cart-col {
      display: none;
    }

    .main-col-main {
      width: 100%;
    }
    .main-col {
      width: 100%;
    }

    .main-col-2 {
      width: 100%;
      //overflow: scroll;
      //max-height: 850px;
    }
  }
}

.sideColDIsplay.cart-col {
  display: block;
}

.cart-col {
  background: #f5f5f5;
  //padding: 6px;
}

.main-grid.big-screen {
  .cart-col {
    width: 40%;
  }

  .main-col {
    //width: 40%;
  }
}

.showOnHover .showOnHoverSon {
  display: none;
}

.showOnHover:hover .showOnHoverSon {
  display: inline-block;
}

//style scroll bar of div
.scrollstyle::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}

.scrollstyleThik::-webkit-scrollbar {
  height: 13px;
  width: 10px;
}

.scrollstyleThin::-webkit-scrollbar {
  height: 3px;
  width: 1px;
}

.scrollstyleThik::-webkit-scrollbar-track,
.scrollstyle::-webkit-scrollbar-track {
  //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scrollstyleThik::-webkit-scrollbar-thumb,
.scrollstyle::-webkit-scrollbar-thumb {
  border-radius: 10px;
  //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.scrollstyleContain {
  overscroll-behavior: contain;
}

.rightit {
  right: 0;
}

.leftit {
  left: 0;
}

ion-card {
  //border-radius: 13px;
}

//google map marker
.marker-label {
  font-size: 1rem !important;
  background: #ededed;
  margin: 59px -42px 0px 0px;
  border-radius: 10px;
  padding: 2px;
}

.fade-animation {
  height: 100%;
  transition: all 0.5s;
}

.faded {
  height: 0;
}


.back {
  //height: 100%;
  ///* max-height: 600px; */
  //width: 100%;
  //background-color: hsla(200, 40%, 30%, .4);
  //background-image: url('https://78.media.tumblr.com/8cd0a12b7d9d5ba2c7d26f42c25de99f/tumblr_p7n8kqHMuD1uy4lhuo2_1280.png'),
  //url('https://78.media.tumblr.com/5ecb41b654f4e8878f59445b948ede50/tumblr_p7n8on19cV1uy4lhuo1_1280.png'),
  //url('https://78.media.tumblr.com/28bd9a2522fbf8981d680317ccbf4282/tumblr_p7n8kqHMuD1uy4lhuo3_1280.png');
  //background-repeat: repeat-x;
  //background-position: 0 20%, 0 100%, 0 50%, 0 100%, 0 0;
  //background-size: 2500px, 800px, 500px 200px, 1000px, 400px 260px;
  //animation: 50s para infinite linear;
}

//url('https://78.media.tumblr.com/66445d34fe560351d474af69ef3f2fb0/tumblr_p7n908E1Jb1uy4lhuo1_1280.png'),
//url('https://78.media.tumblr.com/cae86e76225a25b17332dfc9cf8b1121/tumblr_p7n8kqHMuD1uy4lhuo1_540.png'),
@keyframes para {
  100% {
    background-position: -5000px 20%, -800px 95%, 500px 50%, 1000px 100%, 400px 0;
  }
}

.backSlow {
  height: 100%;
  /* max-height: 600px; */
  width: 100%;
  background-color: hsla(200, 40%, 30%, .4);
  //background-image: url('https://78.media.tumblr.com/8cd0a12b7d9d5ba2c7d26f42c25de99f/tumblr_p7n8kqHMuD1uy4lhuo2_1280.png'),
  //url('https://78.media.tumblr.com/5ecb41b654f4e8878f59445b948ede50/tumblr_p7n8on19cV1uy4lhuo1_1280.png'),
  //url('https://78.media.tumblr.com/28bd9a2522fbf8981d680317ccbf4282/tumblr_p7n8kqHMuD1uy4lhuo3_1280.png'),
  //url('https://78.media.tumblr.com/8cd0a12b7d9d5ba2c7d26f42c25de99f/tumblr_p7n8kqHMuD1uy4lhuo2_1280.png'),
  //url('https://78.media.tumblr.com/28bd9a2522fbf8981d680317ccbf4282/tumblr_p7n8kqHMuD1uy4lhuo3_1280.png');
  background-repeat: repeat-x;
  background-position: 0 20%, 0 100%, 0 50%, 0 100%, 0 0;
  background-size: 2500px, 800px, 500px 200px, 1000px, 400px 260px;
  animation: 500s para2 infinite linear;
}

//url('https://78.media.tumblr.com/66445d34fe560351d474af69ef3f2fb0/tumblr_p7n908E1Jb1uy4lhuo1_1280.png'),
//url('https://78.media.tumblr.com/cae86e76225a25b17332dfc9cf8b1121/tumblr_p7n8kqHMuD1uy4lhuo1_540.png'),
@keyframes para2 {
  100% {
    background-position: -5000px 20%, -800px 95%, 500px 50%, 1000px 100%, 2400px 0;
  }
}

.backLight {
  //background-image: url('https://78.media.tumblr.com/8cd0a12b7d9d5ba2c7d26f42c25de99f/tumblr_p7n8kqHMuD1uy4lhuo2_1280.png'),
  //url('https://78.media.tumblr.com/5ecb41b654f4e8878f59445b948ede50/tumblr_p7n8on19cV1uy4lhuo1_1280.png'),
  //url('https://78.media.tumblr.com/28bd9a2522fbf8981d680317ccbf4282/tumblr_p7n8kqHMuD1uy4lhuo3_1280.png');
}

.no-shadow {
  box-shadow: none;

  .toast-wrapper {
    box-shadow: none;
  }
}

.fit-content {
  width: fit-content;
}

.placeholder {
  color: #ccc;
}


//ion-slides{
//  .swiper-wrapper {
//    gap: 5px;
//  }
//}

.inputText {
  border: 1px solid var(--ion-color-primary);
  --border-radius: 0px !important;
  border-radius: 0px !important;
  //max-width: 330px;
}


ion-fab-button::part(native) {
  //background-color: #b5cbff;
  border-radius: 15px;
  //box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  //color: black;
  white-space: normal;
}

ion-fab-button::part(native):hover::after {
  //background-color: #b5cbff;
}

ion-fab-button::part(native):active::after {
  //background-color: #b5cbff;
}

.no-background{
  --background: none;
  background: none;
}
.bottom-fab1 {
  //--background: #4CAF50;
  //--color: #fff;
  //position: fixed;
  bottom: 30px;
}

.bottom-fab2 {
  //--background: #4CAF50;
  //--color: #fff;
  bottom: 10px;
}

.middle-divider-line{
  span{
    background: #f5f5f5;
    padding: 0 10px;
  }
}
.middle-divider-line::before{
  width: 60%;
  border-top: 0.5px solid #ccc;
  position: absolute;
  content: "";
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 25px;
  opacity: 0.3;
}

ion-fab {
  max-width: 150px;
}